@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Playfair+Display:wght@400;500;600;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background: #ce8860;
  margin: 0;
  font-family: "Playfair Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient-bg {
  background: linear-gradient(
    45deg,
    #e1d2cb 0%,
    #d6bdb6 44.14%,
    #d1b4ac 64.98%,
    #ab908f 100%
  );
}

.menu-animation-enter {
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.menu-animation-enter-active {
  position: absolute;
  transform: translateY(0);
}
.menu-animation-exit {
  position: absolute;
  transform: translateY(0);
}
.menu-animation-exit-active {
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-1000-enter {
  opacity: 0;
}

.fade-1000-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-1000-exit {
  opacity: 1;
}

.fade-1000-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

.animation-element {
  background-color: lightblue;
  padding: 10px;
  margin-top: 10px;
}

@keyframes animatedCircleOrange {
  0% {
    top: 0;
    left: 0;
  }
  10% {
    top: 1px;
    left: 1px;
  }
  20% {
    top: 3px;
    left: 3px;
  }
  30% {
    top: 4px;
    left: 4px;
  }
  40% {
    top: 3px;
    left: 3px;
  }
  50% {
    top: 1px;
    left: 1px;
  }
  60% {
    top: 0px;
    left: 0px;
  }
  70% {
    top: 1px;
    left: 1px;
  }
  80% {
    top: 3px;
    left: 3px;
  }
  90% {
    top: 1px;
    left: 1px;
  }
  100% {
    top: 0;
    left: 0;
  }
}

.animated-circle-orange {
  position: relative;
  animation: animatedCircleOrange 10s ease-in-out infinite alternate;
}

@keyframes animatedCircleWhite {
  0% {
    bottom: 0;
    right: 0;
  }
  10% {
    bottom: 1px;
    right: 1px;
  }
  20% {
    bottom: 3px;
    right: 3px;
  }
  30% {
    bottom: 4px;
    right: 4px;
  }
  40% {
    bottom: 3px;
    right: 3px;
  }
  50% {
    bottom: 1px;
    right: 1px;
  }
  60% {
    bottom: 0px;
    right: 0px;
  }
  70% {
    bottom: 1px;
    right: 1px;
  }
  80% {
    bottom: 3px;
    right: 3px;
  }
  90% {
    bottom: 1px;
    right: 1px;
  }
  100% {
    bottom: 0;
    right: 0;
  }
}

.animated-circle-white {
  position: relative;
  animation: animatedCircleWhite 6s linear infinite alternate;
}

@keyframes flyAnimation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(100vw - 50px), calc(100vh - 50px));
  }
}

@keyframes driftAnimation {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20vw, -20vh);
  }
  50% {
    transform: translate(-10vw, 30vh);
  }
  75% {
    transform: translate(-30vw, -10vh);
  }
}

.circle {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: blue;
  border-radius: 50%;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.circle1 {
  animation-name: flyAnimation;
}

.circle2 {
  animation-name: driftAnimation;
}
