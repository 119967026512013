a.nav-menu-link {
  position: relative;
  line-height: 3rem;
}
a.nav-menu-link {
  transition: all ease 300;
}

a.nav-menu-link:hover::before {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgb(56 54 53 / var(--tw-text-opacity));
  border-radius: 0.25rem;
  position: absolute;
  left: -1rem;
  top: 60%;
  transform: translateY(-45%);
}

@media only screen and (min-width: 768px) {
  a.nav-menu-link {
    position: relative;
    line-height: 6rem;
  }

  a.nav-menu-link:hover::before {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    left: -3rem;
    transition: all ease 300;
  }
}

.close-button {
  position: relative;
  width: 28px;
  height: 32px;
}

.hamburger-icon .line {
  position: absolute;
  width: 100%;
  height: 2px;
}

.hamburger-icon.menu-closed .line:nth-child(1) {
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.hamburger-icon.menu-closed .line:nth-child(2) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.hamburger-icon.menu-closed .line:nth-child(3) {
  transform: translate(-50%, -50%) rotate(0deg);
  transition: all 0.3s ease-in-out;
  bottom: 25%;
  left: 50%;
}

.hamburger-icon.menu-opened .line:nth-child(1) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.3s ease-in-out;
}
.hamburger-icon.menu-opened .line:nth-child(2) {
  top: 50%;
  left: 50%;
  width: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}
.hamburger-icon.menu-opened .line:nth-child(3) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-225deg);
  transition: all 0.3s ease-in-out;
}

.hamburger-icon.menu-opened ~ .close-button-label {
  position: absolute;
  left: 48px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hamburger-icon.menu-closed ~ .close-button-label {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
